<template>
  <main>
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{name: 'home'}"><a>{{$t('breadcrumb_home')}}</a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{name: 'companies'}"><a>{{$t('breadcrumb_companies')}}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link class="is-hidden-mobile" tag="li" :to="{name: 'accounts-org', params: {cid, oid: 'default'}}"><a>{{$t('breadcrumb_accounts')}}</a></router-link>
            <router-link tag="li" :to="{name: 'account', params: {cid, aid}}"><a><account-name :account="account"/></a></router-link>
            <router-link tag="li" :to="{name: 'account', params: {cid, aid}}"><a>{{$t('breadcrumb_reports')}}</a></router-link>
            <router-link tag="li" :to="{name: 'report-vacations', params: {cid, aid}}"><a>{{$t('breadcrumb_report_vacations')}}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>

    <sqr-error :error="eLoadError" />
    <sqr-error :error="eBuildError" />

    <section class="px-2 md:px-0 py-4">
      <div class="container">
        <div class="columns">
          <div class="column">
            <sqr-input-week
              label="report_vacations_start_week"
              v-model="weekFrom"
              @change="load()"
            />
          </div>
          <div class="column">
            <sqr-input-week
              label="report_vacations_end_week"
              v-model="weekUntil"
              @change="load()"
            />
          </div>
          <div class="column">
            <sqr-input-week
              label="report_vacations_end_right"
              v-model="rightUntil"
              @change="load()"
            />
            <div class="help">{{ $t('report_vacations_end_right_help') }}</div>
          </div>
          <div class="column">
            <report-buttons @selected="select" />
          </div>
        </div>
      </div>
    </section>

    <sqr-progess />

    <section class="px-2 md:px-0 py-4" v-if="report">
      <div class="container">
        <h1 class="title">{{ $t('report_vacations_title') }}</h1>
        <h2 class="subtitle">
          <span>
            {{ $t('report_vacations_subtitle') }}
          </span>
          <span>
            {{
              $tc('report_vacations_sheets', sheetsCount, {
                weeks: report.sheetsCount,
              })
            }}
          </span>
          •
          <span>
            {{ $tc('report_sheets_from') }}
            {{ weekFrom | week }}
            {{ $tc('report_sheets_until') }}
            {{ weekUntil | week }}
          </span>
          •
          <span>
            {{ $tc('report_sheets_from') }}
            {{ weekFrom | date }}
            {{ $tc('report_sheets_until') }}
            {{ weekUntil | date }}
          </span>
        </h2>

        <summaries :summaries="summaries" />
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { DateTime } from 'luxon';

import account from './account';
import sReport from './reportSheets';
import eReport from './reportEvents';

import week from '@/filters/week';
import date from '@/filters/date';

import SqrError from '@/sqrd/SqrError';
import SqrInputWeek from '@/sqrd/SqrInputWeek';

import CompanyName from '@/components/CompanyName';
import AccountName from '@/components/AccountName';
import ReportButtons from '@/components/ReportButtons';
import Summaries from '@/components/Summaries';

import yearBoundaries from '@/utils/yearBoundaries';
import computeReportVacations from 'sqr-wotime-core/reports/vacations';

export default {
  name: 'ReportVacations',
  mixins: [account, sReport, eReport],
  filters: {
    week,
    date,
  },
  components: {
    SqrError,
    SqrInputWeek,
    CompanyName,
    AccountName,
    ReportButtons,
    Summaries,
  },
  computed: {
    ...mapState('right', { right: 'record' }),
    sheets() {
      return Object.values(this.sRecord?.sheetsById || {});
    },
    events() {
      const events = Object.values(this.eRecord?.eventsById || {});
      const vacations = events
        .filter(e => e.status === 'pending' || e.status === 'accepted')
        .filter(e => e.entryModelId === 'vacation');
      return vacations;
    },
    report() {
      return computeReportVacations(
        {
          weekStart: this.weekFrom,
          weekEnd: this.weekUntil,
          rightEnd: this.rightUntil,
        },
        this.right,
        this.sheets,
        this.events
      );
    },
    summaries() {
      return [
        {
          op: 'right',
          name: this.$t('report_vacations_op_right'),
          day: this.report?.right?.vacations,
        },
        {
          op: 'used',
          name: this.$t('report_vacations_op_used'),
          day: this.report?.used?.day,
        },
        {
          op: 'planned',
          name: this.$t('report_vacations_op_planned'),
          day: this.report?.planned?.day,
        },
        {
          op: 'remaining',
          name: this.$t('report_vacations_op_remaining'),
          day: this.report?.remaining?.day,
        },
      ];
    },
  },
  data() {
    return {
      weekFrom: '',
      weekUntil: '',
      rightUntil: '',
      buildError: null,
    };
  },
  mounted() {
    const weekYear = DateTime.local().weekYear;
    this.select(weekYear);
  },
  methods: {
    select(weekYear) {
      const res = yearBoundaries(weekYear);
      this.weekFrom = res.weekFrom;
      this.weekUntil = res.weekUntil;
      this.rightUntil = res.rightUntil;
      this.load();
    },
    ...mapActions('right', ['sub']),
    load() {
      const rid = DateTime.fromISO(this.weekFrom).weekYear.toString();
      this.sub({
        path: ['companies', this.cid, 'accounts', this.aid, 'rights'],
        id: rid,
      });
      this.sLoad(this.weekFrom, this.weekUntil);
      this.eLoad(this.weekFrom, this.weekUntil);
    },
  },
};
</script>
