import { mapState, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import { fromPairs } from 'ramda';
import buildRef from '@/utils/buildRef';

export default {
  props: {
    cid: String,
    aid: String,
  },
  data() {
    return {
      eBuildError: null,
    };
  },
  computed: {
    ...mapState('reportEvents', {
      eId: 'id',
      ePath: 'path',
      eExists: 'exists',
      eRecord: 'record',
      eLoading: 'loading',
      eLoadError: 'loadError',
    }),
  },
  methods: {
    ...mapActions('reportEvents', {
      eSub: 'sub',
      eInit: 'init',
      eSave: 'save',
    }),
    async eLoad() {
      try {
        const weekFrom = DateTime.fromISO(this.weekFrom);
        const weekUntil = DateTime.fromISO(this.weekUntil);
        const rightUntil = DateTime.fromISO(this.rightUntil);
        const weekYear = weekFrom.weekYear;
        // not for year before 2000
        if (weekYear < 2000) return;

        // not for different years
        if (weekYear !== weekUntil.weekYear) return;
        if (weekYear !== rightUntil.weekYear) return;

        const id = `${weekYear}-events`;

        await this.eSub({
          path: ['companies', this.cid, 'accounts', this.aid, 'reports'],
          id,
        });

        if (!this.vExists) {
          this.eRebuild();
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async eRebuild() {
      try {
        const weekYear = DateTime.fromISO(this.weekFrom).endOf('week').year;
        const from = DateTime.fromObject({ weekYear, weekNumber: 1 });
        const until = DateTime.fromObject({
          weekYear,
          weekNumber: from.weeksInWeekYear,
        });

        const ref = buildRef([
          'companies',
          this.cid,
          'accounts',
          this.aid,
          'events',
        ]);
        // TODO events must belong to one single weekYear
        // const query = ref.where('years', 'array-contains', weekYear);
        const query = ref
          .where('startDate', '>=', from.toISODate())
          .where('startDate', '<=', until.toISODate());
        const res = await query.get();

        const pairs = res.docs.map(snap => [snap.id, snap.data()]);
        const eventsById = fromPairs(pairs);
        const record = {
          name: `${weekYear}`,
          dateFrom: from.toISODate(),
          dateUntil: until.endOf('week').toISODate(),
          eventsById,
        };
        this.eInit({ path: this.ePath, id: this.eId, record });
        await this.eSave();
        // console.log('rebuild', year);
      } catch (error) {
        this.buildError = error;
        return Promise.reject(error);
      }
    },
  },
};
